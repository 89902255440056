import { AsurionCopyright, AsurionIcon, LocaleSwitcher } from '../../common';
import { Icon, Links, MobileContainer, MobileContent } from './styles';
import React, { VFC as FC } from 'react';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
    copyrightText?: string;
};

export const MobileFooter: FC<Props> = ({ children, copyrightText }) => {
    return (
        <MobileContainer>
            <MobileContent>
                <Icon data-test-id="MxFooter_Mobile_AsurionLogo">
                    <AsurionIcon />
                </Icon>
                <Links>{children}</Links>
                <div>
                    <LocaleSwitcher />
                </div>
                <AsurionCopyright>{copyrightText}</AsurionCopyright>
            </MobileContent>
        </MobileContainer>
    );
};
