import { Container, Content } from '../../common';

import styled from 'styled-components';

export const DesktopContainer = styled(Container)`
    padding: 24px 16px 58px;
`;

export const DesktopContent = styled(Content)`
    height: 111px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Item = styled.div`
    height: 47px;
    display: flex;
    flex-direction: column;
`;

export const Links = styled.div`
    margin-top: auto;
    margin-bottom: 5px;
`;
