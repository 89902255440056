import {
    Partner,
    ProductType,
    defaultPartnerProductConfiguration,
} from '@soluto-private/mx-types';
import React, { FC, createContext, useEffect, useState } from 'react';
import { usePartner, useProductType } from '@soluto-private/mx-context-react';

import type { PartnerProductConfiguration } from '@soluto-private/mx-types';
import { monitor } from '@soluto-private/mx-monitor';
import { useAsync } from 'react-async-hook';

type PartnerProductConfigurationProviderProps = {
    configuration: () => Promise<Partial<PartnerProductConfiguration>>;
};

type UseConfigurationProps = {
    partner: Partner;
    productType: ProductType;
    getConfiguration: PartnerProductConfigurationProviderProps['configuration'];
};

export const PartnerProductConfigurationContext = createContext<
    Partial<PartnerProductConfiguration>
>(defaultPartnerProductConfiguration);

const useConfiguration = ({
    partner,
    productType,
    getConfiguration,
}: UseConfigurationProps) =>
    useAsync(async () => {
        if (partner) {
            try {
                const configuration = await getConfiguration();
                return configuration;
            } catch (error) {
                monitor.warning(
                    'Unhandled error thrown from config',
                    error as Error
                );
            }
        }
    }, [partner, productType, getConfiguration]);

const PartnerProductConfigurationProvider: FC<
    PartnerProductConfigurationProviderProps
> = ({ children, configuration: getConfiguration }) => {
    const [partnerProductConfiguration, setPartnerProductConfiguration] =
        useState<Partial<PartnerProductConfiguration>>({});
    const partner = usePartner();
    const productType = useProductType();
    const { result } = useConfiguration({
        partner,
        productType,
        getConfiguration,
    });

    useEffect(() => {
        if (result) {
            setPartnerProductConfiguration(result);
        }
    }, [result]);

    return (
        <PartnerProductConfigurationContext.Provider
            value={partnerProductConfiguration}
        >
            {children}
        </PartnerProductConfigurationContext.Provider>
    );
};

export default PartnerProductConfigurationProvider;
