import React, { VFC as FC } from 'react';

import { Watermark } from './styles';

export const AsurionCopyright: FC<Props> = ({ children }) => {
    return <Watermark>{children}</Watermark>;
};

type Props = {
    children?: string | JSX.Element | JSX.Element[];
};
