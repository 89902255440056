import { FooterEvent, emitter } from './EventEmmiter';
import { ProductSKU, isProductSKU } from './utils';

import { FooterState } from './types';
import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from './Root.component';
import singleSpaReact from 'single-spa-react';

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary() {
        // Customize the root error boundary for your microfrontend here.
        return <></>;
    },
});

export const { bootstrap, mount, unmount } = lifecycles;

export { ProductSKU, isProductSKU as isProductSKU };

export * from './EventEmmiter';
export * from './types';

export const updateFooter = (opts: FooterUpdateOptions): boolean => {
    return emitter.dispatchEvent(
        new CustomEvent<FooterState>(FooterEvent.Updated, {
            detail: { ...opts },
        })
    );
};

export type FooterUpdateOptions = {
    show: boolean;
    productSKU?: keyof typeof ProductSKU;
};
