import { Container, Content } from '../../common';

import styled from 'styled-components';

export const MobileContainer = styled(Container)`
    height: 219px;
    padding: 42px 16px;
`;

export const MobileContent = styled(Content)`
    height: 135px;
    padding-bottom: 120px;
    flex-direction: column;
`;

export const Icon = styled.div`
    margin-top: 32px;
`;

export const Links = styled.div`
    margin-top: 32px;
`;
