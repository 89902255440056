import React, { FC } from 'react';

import { Link } from './styles';

type LegalLinkProps = {
    id: string;
    label: string;
    url?: string;
    'data-test-id': string;
};

export const LegalLink: FC<LegalLinkProps> = ({
    id,
    'data-test-id': dataTestId,
    label,
    url,
}) => {
    return (
        <>
            {url && label && (
                <Link
                    id={id}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test-id={dataTestId}
                >
                    {label}
                </Link>
            )}
        </>
    );
};
