import { AsurionCopyright, AsurionIcon, LocaleSwitcher } from '../../common';
import { DesktopContainer, DesktopContent, Item, Links } from './styles';
import React, { VFC as FC } from 'react';

type Props = {
    children?: JSX.Element | JSX.Element[];
    copyrightText?: string;
};
export const DesktopFooter: FC<Props> = ({ children, copyrightText }) => {
    return (
        <>
            <DesktopContainer>
                <DesktopContent>
                    <Item>
                        <div data-test-id="MxFooter_Desktop_AsurionLogo">
                            <AsurionIcon />
                        </div>
                        <AsurionCopyright>{copyrightText}</AsurionCopyright>
                    </Item>
                    <Item>
                        <Links>{children}</Links>
                        <div>
                            <LocaleSwitcher />
                        </div>
                    </Item>
                </DesktopContent>
            </DesktopContainer>
        </>
    );
};
