import {
    Key,
    listen,
    mute,
    storage,
    storageEvents,
} from '@soluto-private/mx-context';
import React, {
    FC,
    ReactNode,
    createContext,
    useEffect,
    useState,
} from 'react';

export const defaultContext = {
    client: '',
    language: '',
};

export const GlobalContext = createContext(defaultContext);

type GlobalContextProviderProps = {
    children?: ReactNode;
};

export const GlobalContextProvider: FC<GlobalContextProviderProps> = ({
    children,
}) => {
    const [context, setContext] = useState(() => ({
        client: storage.get(Key.Client) || '',
        language: storage.get(Key.Language) || '',
    }));

    useEffect(() => {
        const handler = () =>
            setContext({ ...context, ...{ client: storage.get(Key.Client) } });
        listen(storageEvents.keyUpdated(Key.Client), handler);
        return () => mute(storageEvents.keyUpdated(Key.Client), handler);
    }, [context]);

    useEffect(() => {
        const handler = () =>
            setContext({
                ...context,
                ...{ language: storage.get(Key.Language) },
            });
        listen(storageEvents.keyUpdated(Key.Language), handler);
        return () => mute(storageEvents.keyUpdated(Key.Language), handler);
    }, [context]);

    return (
        <GlobalContext.Provider value={context}>
            {children}
        </GlobalContext.Provider>
    );
};
