import { FooterLinkType, LinkConfiguration } from '@soluto-private/mx-types';

export function isProductSKU(k: unknown): k is keyof typeof ProductSKU {
    return (
        !!k &&
        typeof k === 'string' &&
        Object.values(ProductSKU).some((v) => v === k)
    );
}

export const ProductSKU = {
    HOMEPLUS1000: 'HOMEPLUS1000',
    HOMEPLUSANNUALMNTH: 'HOMEPLUSANNUALMNTH',
    HOMEPLUSANNUAL: 'HOMEPLUSANNUAL',
    CRICKETHOMEPLUS: 'CRICKETHOMEPLUS',
    HOMEPLUSENT: 'HOMEPLUSENT',
    SOFIHOMEPLUS: 'SOFIHOMEPLUS',
    CONNECTEDMYHOME: 'CONNECTEDMYHOME',
} as const;

type FooterLinkOverride = Partial<Record<FooterLinkType, LinkConfiguration>>;

export const SkuLinkOverrides: Record<
    keyof typeof ProductSKU,
    FooterLinkOverride
> = {
    [ProductSKU.HOMEPLUS1000]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/homeplus/terms-and-conditions/',
        },
    },
    [ProductSKU.HOMEPLUSANNUALMNTH]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/legal/asurion/?_ga=2.179156324.1710917807.1674576322-321469042.1661802043',
        },
    },
    [ProductSKU.HOMEPLUSANNUAL]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/legal/asurion/?_ga=2.179156324.1710917807.1674576322-321469042.1661802043',
        },
    },
    [ProductSKU.CRICKETHOMEPLUS]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/homeplus/terms-and-conditions/',
        },
    },
    [ProductSKU.HOMEPLUSENT]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/pdf/homeplus-entertainment/terms-and-conditions/',
        },
    },
    [ProductSKU.SOFIHOMEPLUS]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/homeplus/terms-and-conditions/',
        },
    },
    [ProductSKU.CONNECTEDMYHOME]: {
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            url: 'https://www.asurion.com/pdf/asurioncompletemyhome-terms/',
        },
    },
};

export const overrideLinksBySKU = (
    sku: string | undefined,
    links: LinkConfiguration[] | undefined
): LinkConfiguration[] | undefined => {
    if (!links || !sku) {
        return links;
    }

    const overrideLinks = SkuLinkOverrides[sku as keyof typeof ProductSKU];
    if (!overrideLinks) {
        return links;
    }

    return Object.values({
        [FooterLinkType.LegalTerms]: {
            type: FooterLinkType.LegalTerms,
            ...links.find((l) => l.type === FooterLinkType.LegalTerms),
            ...overrideLinks[FooterLinkType.LegalTerms],
        },
        [FooterLinkType.PrivacyPolicy]: {
            type: FooterLinkType.PrivacyPolicy,
            ...links.find((l) => l.type === FooterLinkType.PrivacyPolicy),
            ...overrideLinks[FooterLinkType.PrivacyPolicy],
        },
        [FooterLinkType.TermsOfUse]: {
            type: FooterLinkType.TermsOfUse,
            ...links.find((l) => l.type === FooterLinkType.TermsOfUse),
            ...overrideLinks[FooterLinkType.TermsOfUse],
        },
    });
};
