import React, { VFC as FC } from 'react';

import { LegalLink } from './common';
import { LinkConfiguration } from '@soluto-private/mx-types';
import { useTranslation } from 'react-i18next';

export type LinksProps = {
    links?: LinkConfiguration[];
};

export const Links: FC<LinksProps> = ({ links }) => {
    const { t: translate } = useTranslation();

    return (
        <>
            {links?.map(
                (l) =>
                    l.label && (
                        <LegalLink
                            key={l.label}
                            id={l.label}
                            label={translate(l.label)}
                            url={l.url}
                            data-test-id={testIdFromLabel(l.label)}
                        />
                    )
            )}
        </>
    );
};

const testIdFromLabel = (i: string) => `MxFooter_${i.replace(/\s/g, '')}`;
