import React, { VFC as FC, useLayoutEffect, useRef } from 'react';

import { Footer } from './components';
import { GlobalContextProvider } from './providers';
import { PartnerProductConfiguration } from '@soluto-private/mx-types';
import PartnerProductConfigurationProvider from './providers/PartnerProductConfigurationProvider';

export type RootProps = {
    showByDefault?: boolean;
    partnerProductConfiguration: () => Promise<
        Partial<PartnerProductConfiguration>
    >;
};

export const Root: FC<RootProps> = ({
    showByDefault = true,
    partnerProductConfiguration,
}) => {
    const self = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (self.current?.parentElement) {
            self.current.parentElement.style.marginTop = 'auto';
        }
    }, [self]);

    return (
        <div ref={self}>
            <GlobalContextProvider>
                <PartnerProductConfigurationProvider
                    configuration={partnerProductConfiguration}
                >
                    <Footer showByDefault={showByDefault} />
                </PartnerProductConfigurationProvider>
            </GlobalContextProvider>
        </div>
    );
};
