import * as React from 'react';

import { LocaleCode, getLocaleLabel } from './utils';

import { defaultPartnerProductConfiguration } from '@soluto-private/mx-types';
import styled from 'styled-components';
import { useAsync } from 'react-async-hook';
import { useLocale } from './useLocale';
import { useState } from 'react';
import { useSupportedLanguages } from './useSupportedLanguages';
import { useTranslation } from 'react-i18next';

const LocaleSwitcherContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const LocaleList = styled.ul<{ isOpen: boolean }>`
    list-style: none;
    position: absolute;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    background: #fff;
    bottom: 100%;
    border: 1px solid #333;
    margin: 0;
    padding: 3px 0;
    right: 0;
    margin-bottom: 5px;
    border-radius: 4px;
    margin-left: -10px;
`;

const UnstyledButton = styled.button`
    font-size: 16px;
    font-family: inherit;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
    color: #333;
`;

const DropdownButton = styled(UnstyledButton)<{ isOpen: boolean }>`
    :after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-top-color: #000;
        border-bottom-color: #000;
        border-left-width: 4px;
        border-right-width: 4px;
        border-top-width: ${({ isOpen }) => (isOpen ? 0 : 4)}px;
        border-bottom-width: ${({ isOpen }) => (isOpen ? 4 : 0)}px;
        margin-left: 4px;
        margin-bottom: 3px;
    }
`;

const LocaleListButton = styled(UnstyledButton)`
    padding: 5px 10px;
    display: inline-block;
    width: 100%;
    text-align: left;
`;

export const LocaleSwitcher: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { result: supportedLanguages } = useSupportedLanguages();
    const { sessionLocale, setSessionLocale } = useLocale();
    const [selectedLocaleCode, setSelectedLocaleCode] = useState<LocaleCode>();
    const { t } = useTranslation();

    useAsync(async () => {
        if (selectedLocaleCode) {
            await setSessionLocale(selectedLocaleCode);
        }
        setIsOpen(false);
    }, [selectedLocaleCode]);

    const fallbackLocaleCode: LocaleCode = supportedLanguages
        ? supportedLanguages[0].code
        : (defaultPartnerProductConfiguration.i18n.defaultLocale as LocaleCode);

    return supportedLanguages && supportedLanguages.length > 1 ? (
        <LocaleSwitcherContainer>
            {t('Language')}:{' '}
            <DropdownButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {getLocaleLabel(sessionLocale || fallbackLocaleCode)}
            </DropdownButton>
            <LocaleList isOpen={isOpen}>
                {supportedLanguages.map(({ code, displayLang }, i) => (
                    <li key={i}>
                        <LocaleListButton
                            onClick={() => setSelectedLocaleCode(code)}
                        >
                            {displayLang}
                        </LocaleListButton>
                    </li>
                ))}
            </LocaleList>
        </LocaleSwitcherContainer>
    ) : (
        <></>
    );
};
