import { i18n } from '../../../initializations';

export type LocaleCode = Parameters<typeof i18n.setLocale>[0];

export const getLocale = (): LocaleCode | undefined => {
    return i18n.getLocale();
};

export const getLocaleLabel = (localeCode: LocaleCode): string =>
    i18n.localeToLanguage(localeCode ?? 'en-US').displayLang;
