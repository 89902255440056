import styled from 'styled-components';

export const Container = styled.div`
    z-index: 999;
    box-sizing: border-box;
    background-color: white;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    border-top: 1px solid #d5d6da;
    -webkit-font-smoothing: antialiased;
`;

export const Watermark = styled.div`
    margin-top: 8px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
`;

export const Link = styled.a`
    color: inherit;
    text-decoration-line: underline;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0px;
    }
`;
