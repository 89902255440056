import { LocaleCode, getLocale } from './utils';

import { i18n } from '../../../initializations';
import { useState } from 'react';

export const useLocale = () => {
    const [sessionLocale, setSessionLocaleInternal] = useState(getLocale);

    const setSessionLocale = async (locale: LocaleCode) => {
        await i18n.setLocale(locale);
        setSessionLocaleInternal(locale);
        document.documentElement.lang = locale;
    };

    return {
        sessionLocale,
        setSessionLocale,
    };
};
