import {
    AuthenticationState,
    getAuthenticationState,
} from '@soluto-private/mx-app-authentication';
import { FooterEvent, emitter } from '../EventEmmiter';
import {
    FooterLinkType,
    LinkConfiguration,
    defaultPartnerProductConfiguration,
} from '@soluto-private/mx-types';
import React, { VFC as FC, useEffect, useMemo, useState } from 'react';

import { DesktopFooter } from './desktop/footer';
import { FooterState } from '../types';
import { Links } from './Links';
import { MobileFooter } from './mobile/footer';
import { overrideLinksBySKU } from '../utils';
import { usePartnerProductConfiguration } from '../hooks';
import { useTranslation } from 'react-i18next';

type FooterProps = {
    showByDefault?: boolean;
};

const VIEW_BREAKPOINT = '560px';

export const Footer: FC<FooterProps> = ({ showByDefault }) => {
    const { footer } = usePartnerProductConfiguration();

    const [authState] = useState(getAuthenticationState());
    const [show, setShow] = useState<boolean>(!!showByDefault);
    const [sku, setSku] = useState<string>();

    const defaultFooterLinks = defaultPartnerProductConfiguration.footer.links;
    const [links, setLinks] = useState<LinkConfiguration[] | undefined>(
        defaultFooterLinks
    );

    const mobileBreakpoint = `(max-width: ${VIEW_BREAKPOINT})`;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const results = window.matchMedia(mobileBreakpoint);
        if (results.matches !== isMobile) {
            setIsMobile(results.matches);
        }
        const viewportChangeListener = () => {
            setIsMobile(results.matches);
        };
        results.addEventListener('change', viewportChangeListener);
        return () =>
            results.removeEventListener('change', viewportChangeListener);
    }, [isMobile, mobileBreakpoint]);

    useEffect(() => {
        const updateState = (e: CustomEvent<FooterState>) => {
            const { show, productSKU }: FooterState = e.detail;
            setShow(show);
            setSku(productSKU);
        };

        emitter.addEventListener(
            FooterEvent.Updated,
            updateState as EventListener
        );

        return () => {
            emitter.removeEventListener(
                FooterEvent.Updated,
                updateState as EventListener
            );
        };
    });

    useEffect(() => {
        const defaultLinks = footer?.links ?? defaultFooterLinks;
        const overrideLinks = overrideLinksBySKU(sku, defaultLinks);
        const filteredLinks =
            authState === AuthenticationState.LoggedIn
                ? overrideLinks
                : overrideLinks?.filter(
                      (l) =>
                          ![FooterLinkType.LegalTerms].includes(
                              l.type as FooterLinkType
                          )
                  );
        setLinks(filteredLinks);
    }, [footer, sku]);

    const copyrightText = createCopyrightText();
    return (
        <>
            {show &&
                (isMobile ? (
                    <MobileFooter copyrightText={copyrightText}>
                        <Links links={links} />
                    </MobileFooter>
                ) : (
                    <DesktopFooter copyrightText={copyrightText}>
                        <Links links={links} />
                    </DesktopFooter>
                ))}
        </>
    );
};

const createCopyrightText = () => {
    const currYear = useMemo(() => new Date().getFullYear(), []);
    const { t: translate } = useTranslation();
    return `© Asurion 1992-${currYear}. ${translate('All rights reserved')}.`;
};
